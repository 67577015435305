/**
 * CardAgent
 * 
 * @Author: Focci
 * @Date: 2023-07-31 21:39:42
 * @Last Modified by: Focci
 * @Last Modified time: 2023-07-31 21:39:42
 */

import PropTypes from 'prop-types'
import { isArray } from 'lodash-es'
import AspectRatioCover from '@comp/AspectRatioCover'
import LinkPropertyDetail from '@comp/LinkPropertyDetail'
import clsx from 'clsx'
import AgentAvatar from '@comp/page/agent/AgentAvatar'
import SentEmailToAgent from './SentEmailToAgent'
import { listingCardClick } from './lib'

export function CardAgentAvatars({
  agents,
  size = 80,
  className = 'w-12 -mt-6',
  hideAgentName = false,
}) {
  const agentName = isArray(agents) && agents.length === 1
    ? agents[0].name
    : ''
  
  return (
    <div className="flex items-center">
      {agents.map((agent, index) => agent.avatar && (
        <div 
          className={clsx([className, index > 0 ? '-ml-2' : 'z-1'])} 
          key={agent.id + agent.name}
        >
          <AgentAvatar
            name={agent.name}
            avatar={agent.avatar}
            membershipLevel={agent.membership_level}
            width={size}
            height={size}
            wrapperClassName="rounded-full bg-white/60"
            className="object-cover object-top rounded-full"
          />
          {/* <AspectRatioCover
            priority
            brand=""
            src={agent.avatar}
            width={size}
            height={size}
            wrapperClassName="rounded-full bg-white/60"
            className="object-cover object-top rounded-full"
            alt={agent.name}
          /> */}
        </div>
      ))}
      {agentName && !hideAgentName && (
        <div className="ml-2 break-words leading-tight">{agentName}</div>
      )}
    </div>
  )
}

export default function CardAgent({
  inView = false,
  url = '',
  house,
  isPromote,
  agents,
  agentAvatarProps = null
}) {
  const { hideSendEmailButton } = agentAvatarProps || {}
  const {
    officeColor, officeFontColor, agentAvatar, agentName,
    officeName, officeIcon,
  } = house

  if(!(agentAvatar || agentName || officeName || officeIcon)) {
    return null
  }
  
  return (
    <div 
      className="px-2 flex items-center justify-between"
      style={{ backgroundColor: officeColor, color: officeFontColor }}
    >
      <LinkPropertyDetail
        href={url} 
        className="flex items-center justify-between min-h-[3rem] flex-auto relative z-2"
        {...listingCardClick(house)}
      >
        {isPromote && (
          <div className="flex-auto flex items-center">
            {inView && <CardAgentAvatars agents={agents} {...agentAvatarProps} />}
          </div>
        )}
        <div className="w-20">
          {inView && (
            <AspectRatioCover
              priority
              brand=""
              src={officeIcon}
              width={100}
              height={30}
              className="object-contain object-right"
              alt={officeName}
            />
          )}
        </div>
      </LinkPropertyDetail>
      {hideSendEmailButton !== true && house?.simpleAgents?.length > 0 && (
        <div className="shirik-0 ml-2">
          <SentEmailToAgent house={house} />
        </div>
      )}
    </div>
  )
}

CardAgent.propTypes = {
  inView: PropTypes.bool,
  url: PropTypes.string,
  house: PropTypes.object,
  isPromote: PropTypes.bool,
  agents: PropTypes.array,
}
