/**
 * PropertyCardHomePlan
 * 
 * @Author: Focci
 * @Date: 2023-09-04 10:17:44
 * @Last Modified by: Focci
 * @Last Modified time: 2023-09-04 10:17:44
 */

'use client'

import PropTypes from 'prop-types'
import AspectRatioCover from '@comp/AspectRatioCover'
import LinkPropertyDetail from '@comp/LinkPropertyDetail'
import { isArray, pick } from 'lodash-es'
import { useMemo } from 'react'
import PropertyCardScoff from './PropertyCardScoff'
import PropertyCardCover from './cover'
import PropertyIcons from './PropertyIcons'
import { listingCardClick } from './lib'

function PropertyCardHomePlanMeta({ house }) {
  const { title, priceLabel, street } = house
  return (
    <div className="flex flex-col gap-y-0.5">
      <span className="text-f.9">{title}</span>
      <span className="text-f.8">{street}</span>
      <b>{priceLabel}</b>
    </div>
  )
}

function PropertyCardHomePlanItem({ data }) {
  const propertyIcon = useMemo(() => {
    const tmp = pick(data, ['bathrooms', 'bedrooms', 'carspaces'])
    tmp.floorarea = data.floor_area
    tmp.landarea = data.land_area

    return tmp
  }, [data])

  return (
    <LinkPropertyDetail slug={data.slug} {...listingCardClick(data)}>
      <div className="flex gap-x-5">
        <div className="w-20 h-[3.75rem] rounded-sm bg-cf0 shrink-0">
          {isArray(data.oss_pics) && data.oss_pics.length > 0 && (
            <AspectRatioCover
              src={data.oss_pics[0]}
              width={100}
              height={75}
              alt={data.title}
              className="object-contain"
            />
          )}
        </div>
        <div className="flex flex-col gap-y-1">
          <b className="text-f.9">{data.price}</b>
          <PropertyIcons data={propertyIcon} />
        </div>
      </div>
    </LinkPropertyDetail>
  )
}

export default function PropertyCardHomePlan({
  coverProps
}) {
  const { house } = coverProps
  const props = useMemo(() => ({
    ...coverProps,
    meta: <PropertyCardHomePlanMeta house={house} />
  }), [coverProps, house])

  return (
    <div className="rounded-md border border-1 border-cd8 bg-white overflow-hidden">
      <div
        className="flex justify-center items-center h-8 p-1"
        style={{ backgroundColor: house.officeColor }}
      >
        <div className="w-20">
          <AspectRatioCover
            brand="" 
            src={house.officeIcon}
            width={100}
            height={30}
            alt={house.officeName}
            className="object-contain"
          />
        </div>
      </div>
      <PropertyCardScoff
        Cover={PropertyCardCover}
        CoverProps={props}
        className=""
      />
      <div className="p-4">
        {house.homePlans.map((item) => (
          <PropertyCardHomePlanItem key={item.id} data={item} />
        ))}
      </div>
    </div>
  )
}

PropertyCardHomePlan.propTypes = {
  coverProps: PropTypes.object,
}
