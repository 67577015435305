/**
 * AnimationCover
 * 
 * @Author: Focci
 * @Date: 2023-12-04 11:14:21
 * @Last Modified by: Focci
 * @Last Modified time: 2023-12-04 11:14:21
 */

'use client'

import PropTypes from 'prop-types'
import LinkPropertyDetail from '@comp/LinkPropertyDetail'
import { useMemo } from 'react'
import { isArray, pick } from 'lodash-es'
import ShowCaseAgentAnimation from '../../showcase-agent-anmation'
import CoverWrapper from './CoverWrapper'
import { MultipleDefaultCoverScoff } from './DefaultCover'
import Chips from '../Chips'
import { listingCardClick } from '../lib'

export default function AnimationCover({
  house = {},
  showFavorite = false,
  url = '',
  variant,
  onPlayFinish = () => {}
}) {
  const showcaseAgentAnimationProps = useMemo(() => {
    const agent = (isArray(house?.simpleAgents) ? house.simpleAgents : [])[0]
    return {
      ...pick(house, ['propertyIcon', 'images', 'title', 'priceLabel']),
      agentAvatar: agent?.avatar || house?.agentAvatar,
      officeName: house?.officeName,
      agentName: agent?.name || house?.agentName,
      agentEmail: agent?.email,
      agentPhone: agent?.phone
    }
  }, [house])
  
  if(variant === 'multiple') {
    return (
      <LinkPropertyDetail href={url} {...listingCardClick(house)}>
        <MultipleDefaultCoverScoff
          variant={variant}
          house={house}
          title={house.title}
          images={house.images}
          hoverZoomIn={true}
        >
          <div className="h-full relative overflow-hidden">
            <ShowCaseAgentAnimation
              {...showcaseAgentAnimationProps}
              className="h-full"
              onPlayFinish={onPlayFinish}
            />
            <div className="absolute left-2 top-2 z-1 pr-8">
              <Chips {...house} />
            </div>
          </div>
        </MultipleDefaultCoverScoff>
      </LinkPropertyDetail>
    )
  }

  return (
    <CoverWrapper 
      house={house} 
      showFavorite={showFavorite}
      variant={variant}
    >
      <LinkPropertyDetail href={url} {...listingCardClick(house)} className="dont-remove-hover-trigger">
        <ShowCaseAgentAnimation
          {...showcaseAgentAnimationProps}
          className="bg-cf0 aspect-[600/450]"
          onPlayFinish={onPlayFinish}
        />
      </LinkPropertyDetail>
    </CoverWrapper>
  )
}

AnimationCover.propTypes = {
  house: PropTypes.object,
  showFavorite: PropTypes.bool,
  url: PropTypes.string,
  variant: PropTypes.string,
  onPlayFinish: PropTypes.func,
}
