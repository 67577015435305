/**
 * SentEmailToAgent
 * 
 * @Author: Focci
 * @Date: 2024-01-15 15:54:56
 * @Last Modified by: Focci
 * @Last Modified time: 2024-01-15 15:54:56
 */

'use client'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import useApp from '@hook/useApp'
import SVGEmail from '@img/email.svg'
import useLazyComponent from '@hook/useLazyComponent'
import useTracking from '@hook/useTracking'
import { getListingEnquiryProps } from '@comp/page/property/agent-card/lib'
import { useCallback, useState } from 'react'
import { ButtonBase, CircularProgress } from '@comp/material'
import { useTranslations } from 'use-intl'

function SentEmailToAgentTrigger({ 
  color, 
  loading, 
  ...props
}) {
  const { clientWidth } = useApp()
  const ts = useTranslations('search')

  if(clientWidth < 1366) {
    return (
      <ButtonBase {...props} className="rounded-full">
        <div 
          style={{ color }} 
          className="w-8 h-8 rounded-full flex items-center justify-center hover:bg-white/10"
        >
          <SVGEmail className="size-5" />
        </div>
      </ButtonBase>
    )
  }

  return (
    <ButtonBase {...props}>
      <div 
        style={{ color, borderColor: color }}
        className="
          relative h-8 rounded-[.2rem] px-2 border border-2 flex items-center
          cursor-pointer select-none text-[15px] transition-colors
          whitespace-nowrap hover:opacity-70
        "
      >
        <span className={clsx([loading && 'opacity-0', 'font-bold'])}>
          {ts('emailInquiry')}
        </span>
        {loading && (
          <div className="absolute z-1 inset-0 flex items-center justify-center">
            <CircularProgress size="1.2em" sx={{ color }} />
          </div>
        )}
      </div>
    </ButtonBase>
  )
}

export default function SentEmailToAgent({
  house = {}
}) {
  const { officeFontColor: color } = house
  const { Wedget: AgentEnquiry, getWedget } = useLazyComponent()
  const { pushTrackListing, pushTrack } = useTracking()

  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const enquiryProps = getListingEnquiryProps(house)

  const closeDialog = useCallback(() => setOpen(false), [])
  const handleClick = useCallback(async () => {
    // 添加事件
    pushTrackListing('click_email', house)
    pushTrack('click_email', {
      houseId: house.id || 0,
      agentId: house?.simpleAgents?.[0]?.id,
      agencyOfficeId: house?.office_id
    })

    setLoading(true)
    await getWedget(() => import('@comp/dialog/agent-enquiry'))
    setLoading(false)
    setOpen(true)
  }, [getWedget, house, pushTrack, pushTrackListing])

  return (
    <>
      <SentEmailToAgentTrigger
        color={color} 
        loading={loading}
        onClick={handleClick} 
      />
      {enquiryProps.agents && enquiryProps.agents.length > 0 && (
        <AgentEnquiry 
          open={open}
          onClose={closeDialog}
          onSuccess={closeDialog}
          {...enquiryProps}
        />
      )}
    </>
  )
}

SentEmailToAgent.propTypes = {
  house: PropTypes.object,
}
