/**
 * HouseIcons
 * 
 * @Author: Focci
 * @Date: 2024-03-25 10:29:06
 * @Last Modified by: Focci
 * @Last Modified time: 2024-03-25 10:29:06
 */

import SVGTrangleRight from '@img/trangle-right.svg'
import SVGVR from '@img/vr.svg'
import SVGPicture from '@img/picture.svg'

export function HouseImageCountIcons({ house }) {
  const { images } = house
  return (
    <div className="absolute z-[3] right-2 bottom-2 flex items-center gap-1 text-white">
      {images?.length > 0 && (
        <div className="flex items-center gap-1 bg-black/60 rounded-md px-1 py-0.5">
          <SVGPicture width=".8rem" height=".6rem" />
          {images.length}
        </div>
      )}
    </div>
  )
}

export function HouseIcons({ house }) {
  const { isVideo, vrIcon } = house

  return (isVideo || vrIcon) && (
    <div className="pointer-events-none absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 z-1 flex items-center gap-2">
      {isVideo && (
        <div className="bg-black/60 rounded-full text-white w-[3rem] h-[3rem] flex items-center justify-center">
          <SVGTrangleRight width="1.3rem" height="1.3rem" className="-mr-1" />
        </div>
      )}
      {vrIcon && <SVGVR width="3rem" height="3rem" />}
    </div>
  )
}
