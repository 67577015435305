/**
 * Property Card Cover
 * 
 * @Author: Focci
 * @Date: 2023-09-02 10:15:36
 * @Last Modified by: Focci
 * @Last Modified time: 2023-09-02 10:15:36
 */

'use client'

import PropTypes from 'prop-types'
import LinkPropertyDetail from '@comp/LinkPropertyDetail'
import useApp from '@hook/useApp'
import useLazyComponent from '@hook/useLazyComponent'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Waypoint } from 'react-waypoint'
import CoverWrapper from './CoverWrapper'
import CoverScoff from './CoverScoff'
import DefaultCover from './DefaultCover'
import AnimationCover from './AnimationCover'
import { listingCardClick } from '../lib'
import { HouseIcons } from './HouseIcons'

export default function PropertyCardCover({
  inView, // 是否进入了可视区域
  variant = 'normal',
  url,
  house,
  isHouse,
  slideChangeTrackingPageType,
  favoriteTrackingPageType,
}) {
  const { isMobile } = useApp()
  const showFavorite = variant !== 'multiple' && isHouse

  // Swiper
  const { Wedget: SwiperCoverForListingCard, getWedget } = useLazyComponent()
  const handleMouseEnter = useCallback(() => {
    getWedget(() => import('./SwiperCover'), 'SwiperCoverForListingCard')
  }, [getWedget])

  // 动画
  const timer = useRef()
  const [playCount, setPlayCount] = useState(0)
  const [playing, setPlaying] = useState(false)

  const clean = useCallback(() => {
    timer.current && clearTimeout(timer.current)
  }, [])

  const setPlayingCallback = useCallback(() => {
    setPlaying(inView && house.animation && playCount < 2)
  }, [house.animation, inView, playCount])

  const handlePlayFinish = useCallback(() => {
    setPlaying(false)
    setPlayCount((prev) => prev + 1)
    clean()

    timer.current = setTimeout(setPlayingCallback, 3000)
  }, [setPlayingCallback, clean])
  
  useEffect(() => {
    setPlayingCallback()
    return clean
  }, [setPlayingCallback, clean])
  
  // 没有图片
  const imgLen = house.images?.length || 0
  if(imgLen === 0) {
    return (
      <LinkPropertyDetail 
        href={url} 
        {...listingCardClick(house)} 
        className="dont-remove-hover-trigger"
      >
        <CoverScoff variant={variant}>
          {variant === 'normal' && <HouseIcons house={house} />}
        </CoverScoff>
      </LinkPropertyDetail>
    )
  }

  // 播放中介动画
  if(playing) {
    return (
      <AnimationCover
        house={house} 
        showFavorite={showFavorite}
        url={url}
        variant={variant}
        onPlayFinish={handlePlayFinish}
      />
    )
  }
  
  return (
    <>
      <Waypoint onEnter={() => isMobile && handleMouseEnter()} />
      <CoverWrapper 
        house={house}
        favoriteTrackingPageType={favoriteTrackingPageType || slideChangeTrackingPageType}
        showFavorite={showFavorite} 
        variant={variant} 
      >
        {imgLen > 1 && (
          <SwiperCoverForListingCard 
            variant={variant}
            house={house}
            url={url}
            slideChangeTrackingPageType={slideChangeTrackingPageType}
          />
        )}
        
        <LinkPropertyDetail 
          href={url} 
          onMouseEnter={handleMouseEnter} 
          {...listingCardClick(house)}
          className="dont-remove-hover-trigger"
        >
          <DefaultCover
            variant={variant}
            title={house.title}
            house={house}
            images={house.images}
            hoverZoomIn={!isMobile && imgLen <= 1}
          />
          {variant === 'normal' && <HouseIcons house={house} />}
        </LinkPropertyDetail>
      </CoverWrapper>
    </>
  )
}

PropertyCardCover.propTypes = {
  inView: PropTypes.bool,
  variant: PropTypes.oneOf(['normal', 'multiple']),
  url: PropTypes.string,
  house: PropTypes.object,
  isHouse: PropTypes.bool,
}
